import React, { useCallback, useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, CircularProgress, Collapse, Dialog, DialogContent, Divider, Grid, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Tooltip, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import SuccessIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import * as XLSX from 'xlsx';
import { AssignmentTurnedIn, Cached, Cancel, CancelOutlined, CheckBox, Edit, GroupAdd, RemoveCircle, RemoveCircleOutline, Replay, Timer } from '@material-ui/icons';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import GetApp from '@material-ui/icons/GetApp';
import { useHistory, useLocation } from 'react-router-dom';
import {GlobalUseStyles} from "./GlobalUseStyles.js";
import "../config";
import {getBatches, getConsoleMaterials, getBatchOrders, getMaterial, getSharedBy, getSharedWith, shareBatch, unShareBatch, getBatch, DownloadConsoleResults, RefreshStatus, downloadTrace, deleteBatch, DownloadConfigurationXml } from './RestService';
import { Material } from 'models/Material';

import BatchRow from './BatchRow';
import config from '../config';
import { Autocomplete } from '@material-ui/lab';
import { useSnackbar } from 'react-simple-snackbar';
import { errorSnackbarOptions, successSnackbarOptions, warningSnackbarOptions } from './SnackbarOptions';
import cloneDeep from 'lodash/cloneDeep';
import { batch, useSelector } from 'react-redux';
import { useMsal } from '@azure/msal-react';      
import { useIsAuthenticated } from "@azure/msal-react";
import { GridWindow } from '@material-ui/data-grid';
import InfiniteScroll from 'react-infinite-scroller';
import { ConvertedTimeString, GetEmpty, GetEmptyValue } from './Common.js';
import ExecuteDialog from './ExecuteDialog.js';
import { forEach } from 'lodash';

const pdmRelationships = {"has part": "\"cws_configure_has_part\"", "all relationships": "\"cws_configure_all_relationships\"", "no relationships": "\"cws_configure_no_relationships\""};

const initialTitles = [
    { key: 'Characteristic', name: 'Characteristic', resizable: true, width: "240px", editable: true, frozen: true, defaultTitle: "true" },
    { key: 'Type', name: 'Type', resizable: true, width: "30px", editable: true, frozen: true, defaultTitle: "true" },
    { key: 'Default value', name: 'Default', resizable: true, width: "5px", editable: true, frozen: true, defaultTitle: "true" },
  ];
    
let titles = [
    { key: 'Characteristic', name: 'Characteristic', resizable: true, width: "240px", editable: true, frozen: true, defaultTitle: "true" },
    { key: 'Type', name: 'Type', resizable: true, width: "30px", editable: true, frozen: true, defaultTitle: "true" },
    { key: 'Default value', name: 'Default', resizable: true, width: "5px", editable: true, frozen: true, defaultTitle: "true" },
  ];

function ExcelDateToJSDate(date) {
    return new Date(Math.round((date - 25569) * 86400 * 1000)).toUTCString();
}

function useForceUpdate() {
    const [value, setValue] = useState(0);
    return () => setValue(value => value + 1);
}

var fetchBatches = true;
export var consoleMaterialList = [];
export var consoleMaterials = [];

const useStyles = makeStyles((theme) => ({
    root: {
      },
    
      item: {
        padding: "0 8px"
      },
    mainTable: {
        tableLayout: "fixed",
        padding: "0 14px !important"
    },
    tableContainer: {
        position: "absolute", 
        top: "130px", 
        bottom: 0, 
        left: 0, 
        right: 0, 
        width:"95%",
        margin: "28px",
        overflowY: "scroll"
    },
    overflowHidden: {
        overflowY: "hidden"
    },
    tableBatchesLabel: {
        // fontFamily: 'Mulish',
        // fontStyle: "bold"
    },
    tableOrdersLabel: {
        // fontFamily: 'Mulish',
        // fontStyle: "bold"
    },
    batchTitleRow: {
        flexDirection: "column"
    },
    batchTitleCell: {
        padding: "4px 16px",
        width: "20%"
    },
    batchDataRow: {
        flexDirection: "column",
        height: "65px",
        minHeight: "12px"
    },
    activeBatch: {
        backgroundColor: "#E0F2FB"
    },
    activeBatchText: {
        fontWeight: "bold"
    },
    batchCell: {
        padding: "0px 0px",
        width: "20%"
    },
    batchPaginationRow: {
        padding: "4px 16px",
        height: "16px",
    },
    orderTable: {
        backgroundColor: "#EEEEEE",
        tableLayout: "fixed",
        width: "100%",
        borderCollapse: "collapse"
    },
    orderRow: {
        height: 20
    },
    orderFirstRowCell: {
        borderBottom: "none",
        paddingTop: "22px",
        paddingBottom: "2px"
    },
    orderCell: {
        borderBottom: "none",
        paddingTop: "2px",
        paddingBottom: "2px",
    },

    dialogPaper: {
        padding: "0px 0px",
        minWidth: "300px",
        maxWidth: "380px"
    },
    customButton: {
        padding: "0"
    },
    newbatchCell: {
        padding: "0px 0px",
        width: "auto"
    },
    tableres: {
        tableLayout: "inherit",
        padding: "0px !important",
        borderCollapse: "collapse"
    }
}));

export default function TableBatches(props) {
    const classes = useStyles();
    const globalClasses = GlobalUseStyles();
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const [batchesExcel, setBatchesExcel] = useState([]);
    const [batches, setBatches] = useState([]);
    const [filteredBatches, setFilteredBatches] = useState([]);
    const [batchesLoaded, setBatchesLoaded] = React.useState(0);
    const [batchesLoading, setBatchesLoading] = React.useState(false);
    const [endReached, setEndReached] = React.useState(false);
    const [batchesLen, setBatchesLen] = useState(0);
    const [batchData, setBatchData] = useState([]);
    const [newBatchData, setNewBatchData] = useState([]);
    const [allBatchesData, setAllBatchesData] = useState([]);
    const [allSharedData, setAllSharedData] = useState([]);
    const [reRender, setReRender] = useState(false);
    const [currentSelection, setCurrentSelection] = useState([]);
    const [page, setPage] = React.useState(0);
    const [excelDataLen, setExcelDataLen] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [maxBatches, setMaxBatches] = React.useState(25);
    const [showFooter, setShowFooter] = React.useState(false);
    const [showEmpty, setShowEmpty] = React.useState(false);
    const [anchorElDownload, setAnchorElDownload] = React.useState(null);
    const [clickedOrder, setClickedOrder] = React.useState(null);
    const [anchorElBatch, setAnchorElBatch] = React.useState(null);
    const [editLocked, setEditLocked] = React.useState(false);
    const [currentBatch, setCurrentBatch] = React.useState(null);
    const [selectedBatch, setSelectedBatch] = React.useState(null);
    const [shareObj, setShareObj] = React.useState(null);
    const [shareDialogOpen, setShareDialogOpen] = React.useState(false);
    const [shareInputValue, setShareInputValue] = React.useState("");
    const [shareChanged, setShareChanged] = React.useState(false);
    const [selectedUsers, setSelectedUsers] = React.useState([]);
    const [emptyTable, setEmptyTable] = React.useState(false);
    const [initialSelectedUsers, setInitialSelectedUsers] = React.useState([]);
    const [skipModeValues, setSkipModeValues] = useState([]);
    const [errorModeDialogOpen, setErrorModeDialogOpen] = React.useState(false);
    const [orderErrorModeErrors, setOrderErrorModeErrors] = React.useState([]);
    const [errorModeComponents, setErrorModeComponents] = React.useState([]);

    const [openExecuteDialog, setOpenExecuteDialog] = useState(false);
    const [toggleAll, setToggleAll] = useState(false);
    const [callToggleAll, setCallToggleAll] = useState(false);
    const [orderTitles, setOrderTitles] = useState(titles);
    const [caseSelect, setCaseSelect] = React.useState([]);
    const [selectedBatchOrders, setSelectedBatchOrders] = useState(props.selectedBatchOrders == null ? null : props.selectedBatchOrders);
    const [executeSettings, setExecuteSettings] = useState(new Map());

    const [openSuccessSnackbar, closeSuccessSnackbar] = useSnackbar(successSnackbarOptions());
    const [openErrorSnackbar, closeErrorSnackbar] = useSnackbar(errorSnackbarOptions());
    const [openWarningSnackbar, closeWarningSnackbar] = useSnackbar(warningSnackbarOptions());
    const { instance, accounts, inProgress } = useMsal();
    const forceUpdate = useForceUpdate();

    const pathHandler = props.pathHandler;
    const matName = props.materialName;
    const indexHandler = props.indexHandler;
    const setPropsSelectedBatch = props.selectedBatch;
    const selectedOrders = props.selectedOrders;
    const selectedKMID = props.selectedKMID;
    const selectedKMRev = props.selectedKMRev;
    const history = useHistory();
    const location = useLocation();    
    const isAuthenticated = useIsAuthenticated();
    const tempUsers = [];
    //let currentBatch = null;
    let currentBatches = null;

    const [id, setID] = useState(2);

    const loading = async () => {
        if (batchesLoading == false)
        {
            setBatchesLoading(true);

            setBatchesLoaded(batchesLoaded+25);
            mapBatches();
            setBatchesLoading(false);
            if (props.materialSearch == "")
            {
                if (batchesLoaded > batchData.length)
                {
                    setEndReached(true);
                }
            }
            else 
            {
                if (batchesLoaded >= filteredBatches.length)
                {
                    setEndReached(true);
                }
            }
        }
    }

    const handleBatchEditClick = () => {
        history.push("/batch");
        indexHandler(1);
    };

    const handleBatchCopyClick = () => {
        let tempBatch = cloneDeep(currentBatch);
        tempBatch["id"] = "";
        tempBatch["name"] = "";
        tempBatch["status"] = "Not run";
        let tmpOrders = [];
        if (!GetEmpty(tempBatch["orders"]))
        {
            tempBatch["orders"].forEach(element => {
                tmpOrders.push({
                    "batchID": "",
                    "name": element["name"],
                    "description": "",
                    "status": "Not run",
                    "message": null,
                    "resultFiles": [],
                    "characteristics": element["characteristics"]
                })
            });
        }
        props.setCopyBatch(true);
        props.setNewBatch(true);
        setPropsSelectedBatch(tempBatch);
        if (!GetEmpty(tempBatch["orders"]))
        {
            props.selectedOrders(tempBatch["orders"]);
        }
        history.push("/batch");
        indexHandler(1);
    };

    const handleSetSelectedShareUsers = (e, value) => {
        setSelectedUsers([...value]);
        let tempAllSharedAndSelected = value;
        if (shareObj != null)
        {
            if (shareObj["batch"] != undefined)
            {
                tempAllSharedAndSelected = tempAllSharedAndSelected.concat(shareObj["batch"]["sharedTo"]);
            }
        }
        let tmpShareChanged = false;
        if (initialSelectedUsers.length != tempAllSharedAndSelected.length)
        {
            tmpShareChanged = true;
        }
        // Number of users has not changed, check if the arrays contain different strings
        else if (initialSelectedUsers.length == tempAllSharedAndSelected.length)
        {
            if (!initialSelectedUsers.every(function (item){return tempAllSharedAndSelected.indexOf(item) > -1}))
            {
                tmpShareChanged = true;
            }
        }
        setShareChanged(tmpShareChanged);
    };

    const handleBatchDeleteClick = () => {
        if (confirm("Are you sure you want to delete batch " + currentBatch["name"] + "?"))
        {
            var delResult = null;
            var message = "";
            setAnchorElBatch(null);
            const data = async () => {
                delResult = await deleteBatch(instance, currentBatch["id"], accounts[0].username);
            }
            data().catch((error)=>{
                console.error(error);
                message = "Error: " + error.message;
            }).then(() => {
                if (message != "")
                {
                    openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{message}</p></div>,300000);
                } 
                else 
                {
                    let tmpBatchData = [...props.batches];
                    var newBatchData = tmpBatchData.filter((obj) => {
                        return obj["id"] !== currentBatch["id"];
                    });
                    props.setBatches(newBatchData);
                    openSuccessSnackbar(<div style={{whiteSpace: "nowrap"}}><SuccessIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Batch deleted successfully."}</p></div>,300000);
                    if (newBatchData.length == 0)
                    {
                        setBatches([]);
                        setEmptyTable(true);
                    }
                }
            });
        }
    };

    const handleBatchShareOpenClick = (e, newShareObj, _batch = null) => {
        setShareChanged(false);
        if (anchorElBatch)
        {
            if (currentBatch != null && currentBatch["sharedTo"] != null)
            {
                setInitialSelectedUsers(currentBatch["sharedTo"]);
            }
        }
        else
        {
            if (_batch != null && _batch["sharedTo"] != null)
            {
                setInitialSelectedUsers(_batch["sharedTo"]);
            }
        }
        setSelectedUsers([]);
        if (_batch != null){
            setCurrentBatch(_batch);
        }

        if (newShareObj != null) {
            setShareObj(newShareObj);
        }
        setShareDialogOpen(true);
        setAnchorElBatch(null);
    };

    const handleShareDialogClose = () => {
        let tempAllSharedAndSelected = selectedUsers;
        if (shareObj != null)
        {
            if (shareObj["batch"] != undefined)
            {
                tempAllSharedAndSelected = tempAllSharedAndSelected.concat(shareObj["batch"]["sharedTo"]);
            }
        }
        let changed = false;
        if (initialSelectedUsers.length != tempAllSharedAndSelected.length)
        {
            changed = true;
        }
        // Number of users has not changed, check if the arrays contain different strings
        else if (initialSelectedUsers.length == tempAllSharedAndSelected.length)
        {
            if (!initialSelectedUsers.every(function (item){return tempAllSharedAndSelected.indexOf(item) > -1}))
            {
                changed = true;
            }
        }
        if (changed == true)
        {
            if (confirm("The list of shared people has changed and need to be saved by clicking Share-button or they won't be saved. Are you sure you want to exit?"))
            {
                setShareDialogOpen(false);
            }
        }
        else
        {
        setShareDialogOpen(false);
        }
    };

    const handleBatchShareClick = () => {
        let tempUsers = selectedUsers;
        console.log("USERS: ", tempUsers);
        if (shareObj != null)
        {
            if (shareObj["batch"] != undefined)
            {
                tempUsers = tempUsers.concat(shareObj["batch"]["sharedTo"]);
            }
        }
        let message = "";
        let shareData = null;

        const data = async () => {
            if (tempUsers.length == 0)
            {
                shareData = await unShareBatch(instance, currentBatch["id"]);
            }
            else
            {
                tempUsers.forEach(element => {
                    let domain = element.substring(element.lastIndexOf("@"));
                    console.log("DOMAIN: ", domain);
                    if (domain.toLowerCase() != "@kone.com")
                    {
                        if (message == "")
                        {
                            message = "Invalid domains: ";
                        }
                        message += element + " ";
                    }
                });
                if (message == "")
                {
                    shareData = await shareBatch(instance, currentBatch["id"], tempUsers);
                }
            }
        };
        data().catch((error)=>{
            console.error(error);
            message = "Error: " + error.message;
        }).then(() => {
            if (message != "")
            {
                openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{message}</p></div>,300000);
            } 
            else 
            {
                openSuccessSnackbar(<div style={{whiteSpace: "nowrap"}}><SuccessIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Batch sharing list succesfully updated."}</p></div>,300000);
                if (typeof props.setNewBatch === 'function')
                {
                    props.setNewBatch(false);
                }
                currentBatch["sharedTo"] = tempUsers;

                const newName = currentBatch["name"];
                const newObj = {};
                let tempArr = allSharedData.filter((obj) => {                    
                    return newName != Object.keys(obj)[0];
                });
                newObj[newName] = tempUsers;
                tempArr.push(newObj);
                setAllSharedData([...tempArr]);
            }
            
            setShareDialogOpen(false);
        });
        

        // If tempUsers users array empty, unshare
        // if (tempUsers.length == 0)
        // {
        //     const data = async () => {
        //         shareData = await unShareBatch(instance, currentBatch["name"], tempUsers);
        //     };
        //     data().catch((error)=>{
        //         console.error(error);
        //         message = error.message;
        //     }).then(() => {
        //         if (message != "")
        //         {
        //             openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Error: " + message}</p></div>);
        //         } 
        //         else 
        //         {
        //             openSuccessSnackbar(<div style={{whiteSpace: "nowrap"}}><SuccessIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Batch unshare successful."}</p></div>);
        //             props.setNewBatch(false);
        //         }
        //         const newName = currentBatch["name"];
        //         let tempArr = allSharedData.filter((obj) => {                    
        //             return newName != Object.keys(obj)[0];
        //         });
        //         setAllSharedData([...tempArr]);
        //         setShareDialogOpen(false);
        //     });
        // }
        // else
        // {
        //     const data = async () => {
        //         shareData = await shareBatch(instance, currentBatch["name"], tempUsers);
        //     };
        //     data().catch((error)=>{
        //         console.error(error);
        //         message = error.message;
        //     }).then(() => {
        //         if (message != "")
        //         {
        //             openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Error: " + message}</p></div>);
        //         } 
        //         else 
        //         {
        //             openSuccessSnackbar(<div style={{whiteSpace: "nowrap"}}><SuccessIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Batch shared successfully."}</p></div>);
        //             if (typeof props.setNewBatch === 'function')
        //             {
        //                 props.setNewBatch(false);
        //             }
        //         }
        //         console.log("ALLSHARED: ", allSharedData);
        //         const newName = currentBatch["name"];
        //         const newObj = {};
        //         let tempArr = allSharedData.filter((obj) => {                    
        //             return newName != Object.keys(obj)[0];
        //         });
        //         newObj[newName] = tempUsers;
        //         tempArr.push(newObj);
        //         setAllSharedData([...tempArr]);
        //         setShareDialogOpen(false);
        //     });
        // }
        
        
    }

    const handleBatchRemoveShareClick = (e, _currentBatch) => {

        let message = "";
        let shareData = null;
        let tempShareObj = cloneDeep(shareObj);
        console.log("e: ", e);
        console.log("shareObj: ", shareObj);
        //shareObj["batch"]["sharedTo"]
        let index = tempShareObj["batch"]["sharedTo"].indexOf(e);
        if (index !== -1)
        {
            tempShareObj["batch"]["sharedTo"].splice(index, 1);
            setShareObj(tempShareObj);
        }

        let tempAllSharedAndSelected = selectedUsers;
        if (tempShareObj != null)
        {
            if (tempShareObj["batch"] != undefined)
            {
                tempAllSharedAndSelected = tempAllSharedAndSelected.concat(tempShareObj["batch"]["sharedTo"]);
            }
        }
        let tmpShareChanged = false;
        if (initialSelectedUsers.length != tempAllSharedAndSelected.length)
        {
            tmpShareChanged = true;
        }
        // Number of users has not changed, check if the arrays contain different strings
        else if (initialSelectedUsers.length == tempAllSharedAndSelected.length)
        {
            if (!initialSelectedUsers.every(function (item){return tempAllSharedAndSelected.indexOf(item) > -1}))
            {
                tmpShareChanged = true;
            }
        }
        setShareChanged(tmpShareChanged);
        // const data = async () => {
        //     shareData = await unShareBatch(_currentBatch, [e]);
        // };
        // data().catch(()=>{}).then(() => {
        //     console.log("SHAREDATA: ", shareData);
        // });
        // if (message != "")
        // {
        //     openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Error: " + message}</p></div>);
        // } 
        // else 
        // {
        //     openSuccessSnackbar(<div style={{whiteSpace: "nowrap"}}><SuccessIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px", zIndex: "2000 !important"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Removed share successfully."}</p></div>);
        // }
    }

    const handleCloseErrorMode = () => {
        setErrorModeDialogOpen(false);
    };

    const handleSetErrorModeDialog = (errorArray) => {
        let tmpComponents = [];
        for (var i = 0; i < errorArray.length; i++)
        {
            tmpComponents.push(
                <ListItem key={"errorModeError" + i.toString()} style={{padding: "0", marginTop: "2px", wordWrap: "break-word"}}>
                <ListItemText
                    
                    primaryTypographyProps={{ style: { whiteSpace: "normal" } }}
                    primary={<Typography variant="body1">{errorArray[i]}</Typography>}
                    style={{ paddingLeft: "4px", color: "#666", font: "inherit"}}
                    className={clsx(globalClasses.addBorder)}
                    />
                </ListItem>
                    );
        }
        setErrorModeComponents([...tmpComponents]);
        setOrderErrorModeErrors(errorArray);
        setErrorModeDialogOpen(true);
    }

    const handleBatchExecuteOpenClick = (e, _batch = null) => {
        console.log("EXECUTE BATCH: ", _batch);
        let tmpExecuteSettings = new Map();
        if (GetEmpty(_batch) == false)        
        {
            if (GetEmpty(_batch["executeArguments"]) == false)
            {
                for (const [key, value] of Object.entries(_batch["executeArguments"])) {        
                    if (key == "levelOfScan")
                    {
                    if (value == "deep_check")
                        tmpExecuteSettings.set("deepScan", true);
                    }
                    else
                    {
                        if (typeof value === 'string' || value instanceof String)
                        {
                            tmpExecuteSettings.set(key, (value === 'true'));
                        }
                        else
                        {
                            tmpExecuteSettings.set(key, (value === true));
                        }
                    }
                }
                tmpExecuteSettings.delete("environment");                
                tmpExecuteSettings.delete("relationships");   
                console.log("tmpExecuteSettings edit values:", tmpExecuteSettings);  
            
            }        
            
            console.log("selectedBatch: ", _batch);
            
            if (GetEmpty(_batch["executeOrders"]) == false)
            {
                if (GetEmpty(_batch["orders"]) == false)
                {   
                    let initialOrders = [];
                    let allOrders = [];
                    let regex = new RegExp("(?:" + _batch["executeOrders"].join("|") + ")$");
                    _batch["orders"].map((obj) => {  
                        let setSelected = false;   
                        if(obj["name"].match(regex))
                        {
                            let splitObjNameArr = obj["name"].split("_");
                            let splitObjName = "";
                            if (splitObjNameArr.length > 1)
                            {
                                splitObjName = splitObjNameArr[splitObjNameArr.length-1];
                                splitObjName = splitObjName.replace(/^0+/, '');
                            }
                            else
                            {
                                let splitObjNameArr2 = obj["name"].split("-");
                                if (splitObjNameArr2.length >= 2)
                                {
                                    splitObjName = splitObjNameArr2[splitObjNameArr2.length-2].slice(-4) + "-" + splitObjNameArr2[splitObjNameArr2.length-1];
                                    splitObjName = splitObjName.replace(/^0+/, '');
                                }
                                else
                                {
                                    splitObjName = obj["name"].slice(-4);
                            splitObjName = splitObjName.replace(/^0+/, '');
                                }
                            }
                            setSelected = true;
                            initialOrders.push({ key: obj["name"], name: splitObjName, resizable: true, width: "5%", editable: true, description: obj["description"], selected: true })
                        }
                        let splitObjNameArr = obj["name"].split("_");
                        let splitObjName = "";
                        if (splitObjNameArr.length > 1)
                        {
                            splitObjName = splitObjNameArr[splitObjNameArr.length-1];
                        splitObjName = splitObjName.replace(/^0+/, '');
                        }
                        else
                        {
                            let splitObjNameArr2 = obj["name"].split("-");
                            if (splitObjNameArr2.length >= 2)
                            {
                                splitObjName = splitObjNameArr2[splitObjNameArr2.length-2].slice(-4) + "-" + splitObjNameArr2[splitObjNameArr2.length-1];
                                splitObjName = splitObjName.replace(/^0+/, '');
                            }
                            else
                            {
                                splitObjName = obj["name"].slice(-4);
                                splitObjName = splitObjName.replace(/^0+/, '');
                            }
                        }
                        allOrders.push({ key: obj["name"], name: splitObjName, resizable: true, width: "5%", editable: true, description: obj["description"], selected: setSelected })
                    })
                    console.log("initialOrders:", initialOrders); 
                    if (!initialOrders.some(obj => obj["key"] === "All"))
                    {
                        let newObj = { key: 'All', name: 'All', resizable: true, width: "240px", editable: true, frozen: true, defaultTitle: "false", description: "" }
                        if (initialOrders.length == _batch["orders"].length)
                        {
                            initialOrders.push(newObj);
                            allOrders.push(newObj);
                        }
                    }
                    setCaseSelect([...initialOrders]);

                    let tmpOrderTitles = [...titles];
                    allOrders.forEach(element => {
                        if (!tmpOrderTitles.some((obj) => obj["name"] == element["key"]) )
                        {
                            tmpOrderTitles.push(element);
                        }
                    });
                    setOrderTitles(tmpOrderTitles);
                    console.log("tmpOrderTitles: ", tmpOrderTitles);
                    console.log("selectedBatch2: ", _batch);
                    console.log("initialOrders: ", initialOrders);
                    
                } 
            }
        }
        
        setExecuteSettings(tmpExecuteSettings);
        setSelectedBatch(_batch);
        console.log("openExecuteDialog: ", openExecuteDialog);
        setOpenExecuteDialog(!openExecuteDialog);
        setReRender(!reRender);
    };

    const handleDownloadClose = (e) => {
        setAnchorElDownload(null);
        console.log("close");
    };
    const handleOrderXML = (e) => {
        //DownloadConsoleResults(instance, clickedOrder["name"], clickedOrder["configurationId"], "XML", accounts[0].username)
        DownloadConfigurationXml(instance, clickedOrder["configurationId"], accounts[0].username)
        setAnchorElDownload(null); 
    };
    const handleOrderMFGDownload = (e) => {
        DownloadConsoleResults(instance, clickedOrder["name"], clickedOrder["configurationId"], "MFG", accounts[0].username)
        setAnchorElDownload(null); 
    }; 
    const handleCADLog = (e) => { 
        DownloadConsoleResults(instance, clickedOrder["name"], clickedOrder["configurationId"], "LOG", accounts[0].username)
      setAnchorElDownload(null); 
    }; 
    const handleCADResult = (e) => { 
        DownloadConsoleResults(instance, clickedOrder["name"], clickedOrder["configurationId"], "PDM", accounts[0].username)
      setAnchorElDownload(null); 
    }; 
    const handleCADCheck = (e) => { 
        DownloadConsoleResults(instance, clickedOrder["name"], clickedOrder["configurationId"], "MCLOG", accounts[0].username)
      setAnchorElDownload(null); 
    };
    const handleTrace = (e) => { 
        downloadTrace(instance, clickedOrder["name"], accounts[0].username)
       setAnchorElDownload(null); 
     };
    

    const handleBatchClose = () => {
        setAnchorElBatch(null);
    };

    const handleSetAnchorElBatch = (e, b, kmID, kmRev, clickedOrders, newShareObj) => {
        setAnchorElBatch(e.currentTarget);
        setPropsSelectedBatch(b);
        setCurrentBatch(b);
        selectedKMID(b["material"]);
        selectedKMRev(b["materialRevision"]);
        selectedOrders(clickedOrders);
        setShareObj(newShareObj);
        setEditLocked(b["status"] == "Processing" || b["createdBy"] != accounts[0].username ? true : false);
    };

    const handleBatchClick = (index, clickedBatch) => {
        //console.log("INDEX: " + index);
        //console.log(clickedBatch);
        var indexOfValue = Array.from(currentSelection).indexOf(index);
        //var temp = currentSelection.map(item => Object.assign({}, item));
        if (indexOfValue > -1) {
            //temp.splice(indexOfValue, 1);
            setCurrentSelection(currentSelection => currentSelection.filter(item => item !== index));
        }
        else {
            //temp.push(index);
            currentSelection.push(index);
            setCurrentSelection([...currentSelection]);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setReRender(!reRender);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setReRender(!reRender);
    };

    const handleEditClick = (e, clickedBatch, kmID, kmRev, clickedOrders) => {
        setPropsSelectedBatch(clickedBatch);
        selectedKMID(kmID);
        selectedKMRev(kmRev);
        selectedOrders(clickedOrders);
        history.push("/batch");
        indexHandler(1);
    };

    const handleCancelClick = (clickedBatch) => {
        let tmpBatchData = batchData;
        var batch = tmpBatchData.findIndex((obj) => {
            return obj["id"] == clickedBatch["id"];
        });
        tmpBatchData[batch]["status"] = clickedBatch["status"];
        tmpBatchData[batch]["orders"].forEach(order => {
            if (order["status"] == "Processing")
            {
                order["status"] = "Not run";
            }
        });
        props.setBatches(tmpBatchData);
    };


    const fetchData = async () => { 
        if (fetchBatches == true) {
            //console.log("fetchBatches:", fetchBatches);
            fetchBatches = false;
            
            console.log("Retrieving batch data for " + accounts[0].username);
            
            const batches = await getBatches(instance);
            //console.log("BATCHES: ", batches);
            if (batches["exception"])
            {
                openWarningSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{batches["exception"]}</p></div>,300000)
                //showEmpty = true;
            }
            else
            {
                setBatchData([...batches]);
                props.setBatches([...batches]);
                // props.setReRender(!props.reRender);
            }
            //console.log("Retrieving material revision list from CWS Console for " + accounts[0].username);
            //let materialRevisions = []; //await getConsoleMaterials(instance,"SVC_CWS_API");
            /*let foundMaterials = [];
            if(Array.isArray(materialRevisions)) {
                let materials = [""];
                for (let mr = 0; mr < materialRevisions.length; mr++) {
                let matrev = materialRevisions[mr].split("/"); 
                if (materials.includes(matrev[0]) == false) {
                    materials.push(matrev[0]);   

                    const newData = await getMaterial(instance,matrev[0]);
                    if(Array.isArray(newData)) {
                    newData.forEach(element2 => {
                        foundMaterials.push(new Material(element2["id"], element2["revision"], element2["batches"]));
                    });
                    }
                }
                }
                consoleMaterialList = materialRevisions;
                consoleMaterials = materials;
                setBatchData([...foundMaterials]);
            console.log("materials:",materials);
            console.log("foundmaterials: ", foundMaterials);
            }*/
            }
    }

    const mapBatches = () => {
        //console.log("BATCHDATA: ", batchData);
        if (batchesLoading == true) {return;}
        if (batchData == null) {return;}
        if (batchData.length == 0) { return; }
        // if (batches.length > 0) {return;}  
        let newBatches = [];
        let lastID = "CWS0000000000"
        let allBatches = [];

        emptyRows = rowsPerPage - Math.min(rowsPerPage, batchData.length - page * rowsPerPage);
        setBatchesLen(batchData.length);
        // console.log("BATCHES: ",batchData);
        //tempOrders.sort((a,b) => parseInt(a["name"].slice(-4), 10) - parseInt(b["name"].slice(-4), 10));
        let tmpBatchData = [...batchData];
        tmpBatchData.sort((a, b) => new Date(a["created"]).valueOf() - new Date(b["created"]).valueOf()).reverse();
        tmpBatchData.sort((a, b) => +(a["status"] == "Not run" && a["status"] != b["status"]));
        if (props.statusFilter)
        {
            if (!props.statusFilter.includes("All"))
            {
                tmpBatchData = tmpBatchData.filter((b) => props.statusFilter.includes(b["status"]));
            }
        }
            
        let slicedArray = tmpBatchData.slice();
        
        slicedArray.map((b, index3) => {
            //console.log(b);
            if (props.showShared == true)
            {
                b = b["batch"];
            }
            let IDStr = b["name"].replace(/\D/g, '');
            let num = parseInt(IDStr, 10);
            let IDStr2 = lastID.replace(/\D/g, '');
            let num2 = parseInt(IDStr2, 10);

            if (num > num2) {
                props.setLastID(b["name"]);
                lastID = b["name"];
            }

            let statusIcon = null;
            if (b["status"] === "Success") {
                statusIcon = <SuccessIcon className={globalClasses.successIcon} />
            }
            else if (b["status"] === "Failed") {
                statusIcon = <ErrorIcon className={globalClasses.errorIcon} />
            }
            else if (b["status"] === "InProgress" || b["status"] === "Processing") {
                statusIcon = <Cached className={globalClasses.inProgressIcon} />
            }
            else if (b["status"] === "Not run") {
                statusIcon = <RemoveCircleOutline className={globalClasses.NotRunIcon} />
            }
            else if (b["status"] === "Cancelled") {
                statusIcon = <Cancel className={globalClasses.cancelledIcon} />
            }
            let date = new Date(b["lastRun"]);

            const orderSecondaryText = <>{b["createdBy"]}
                &nbsp;&nbsp;{ConvertedTimeString(b["created"])}</>

            let orders = [];
            allBatches.push({ "created": b["created"], "key": b["id"] + "_" + index3, "currentSelection": currentSelection, "classes": classes, "globalClasses": globalClasses, "lastRun": b["lastRun"], "index": index3, "batch": b, "material": b["material"], "statusIcon": statusIcon, "orderSecondaryText": orderSecondaryText, "handleBatchClick": handleBatchClick, "handleEditClick": handleEditClick })
            // newBatches.push(
            //     <BatchRow key={b["name"] + "_" + index3} currentSelection={currentSelection} classes={classes} globalClasses={globalClasses} lastRun={lastRun} index={index3} batch={b} material={d} statusIcon={statusIcon} orderSecondaryText={orderSecondaryText} handleBatchClick={handleBatchClick} handleEditClick={handleEditClick}></BatchRow>
            // );
            // currentBatches = newBatches;
            // setBatches(newBatches);

        });
        let notRunBatches = allBatches.filter((e) => e["batch"]["status"] == "Not run");
        let runBatches = allBatches.filter((e) => e["batch"]["status"] != "Not run");

        notRunBatches.sort((a, b) => new Date(a["batch"]["created"]).valueOf() - new Date(b["batch"]["created"]).valueOf()).reverse();
        runBatches.sort((a, b) => new Date(a["batch"]["lastRun"]).valueOf() - new Date(b["batch"]["lastRun"]).valueOf()).reverse();

        let newAllBatches = [];
        let newAllBatchData = [];
        let tmpFilteredBatches = [];
        newAllBatches = newAllBatches.concat(runBatches);
        newAllBatches = newAllBatches.concat(notRunBatches);
        
        if (props.showShared == true)
        {
            let newData = null;
            let sharedBatches = [];
            allBatches.sort((a, b) => new Date(a["lastRun"]).valueOf() - new Date(b["lastRun"]).valueOf()).reverse();
            if (newAllBatches.length > 0) {
                setAllBatchesData(newAllBatches);
                newAllBatches.forEach(element => {
                    let batchEl = element["batch"];
                    let newShareObj = null;
                    let hasBeenShared = false;
                    newAllBatchData.push(batchEl);
                    newBatches.push(
                        <BatchRow batchesErrorModes={props.batchesErrorModes} handleSetErrorModeDialog={handleSetErrorModeDialog} sharedToMe={true} handleBatchExecuteOpenClick={handleBatchExecuteOpenClick} setSelectedBatch={setSelectedBatch} handleCancelClick={handleCancelClick} setClickedOrder={setClickedOrder} shareObj={newShareObj} handleBatchShareOpenClick={handleBatchShareOpenClick} hasBeenShared={hasBeenShared} handleSetAnchorElBatch={handleSetAnchorElBatch} setAnchorElDownload={setAnchorElDownload} key={element["key"]} currentSelection={element["currentSelection"]} classes={element["classes"]} globalClasses={element["globalClasses"]} lastRun={element["lastRun"]} index={element["index"]} batch={element["batch"]} material={element["material"]} statusIcon={element["statusIcon"]} orderSecondaryText={element["orderSecondaryText"]} handleBatchClick={element["handleBatchClick"]} handleEditClick={element["handleEditClick"]}></BatchRow>
                    );
                });
            }
            currentBatches = newBatches;
            if (newBatches.length == 0)
            {
                setShowEmpty(true);
            } 
            else 
            {
                setShowEmpty(false);
            }
                setNewBatchData(newAllBatchData);
                setBatches(newBatches);
                   
        }
        else {
            //newAllBatches = newAllBatches.filter((e) => e["batch"]["createdBy"] == accounts[0].username);
            allBatches.sort((a, b) => new Date(a["lastRun"]).valueOf() - new Date(b["lastRun"]).valueOf()).reverse();
            //console.log("ALLBATCHES: ", allBatches);
            //console.log("NEWALL2: ", newAllBatches);
            if (newAllBatches.length > 0) {
                
                setAllBatchesData(newAllBatches);
                //setAllSharedData(sharedTemp);
                tmpFilteredBatches = tmpFilteredBatches.concat(newAllBatches);
                if (props.materialSearch)
                {
                    tmpFilteredBatches = tmpFilteredBatches.filter((element) => element["batch"]["material"].toString().toLowerCase().indexOf(props.materialSearch.toLowerCase()) !== -1 
                    || element["batch"]["materialRevision"].toLowerCase().indexOf(props.materialSearch.toLowerCase()) !== -1 
                    || (element["batch"]["material"] + "/" + element["batch"]["materialRevision"]).toLowerCase().indexOf(props.materialSearch.toLowerCase()) !== -1
                    || (element["batch"]["material"] + "/").toLowerCase().indexOf(props.materialSearch.toLowerCase()) !== -1
                    || element["batch"]["name"].toString().toLowerCase().indexOf(props.materialSearch.toLowerCase()) !== -1)
                }

                if (props.homePage == true) {
                    newAllBatches = newAllBatches.slice(0, 5);
                }
                else {
                    newAllBatches = newAllBatches.slice(0, batchesLoaded);
                }
                newAllBatches.forEach(element => {
                    let hasBeenShared = false;
                    let newShareObj = null;
                    //console.log ("BATCH: ", element);
                    if (element["batch"]["sharedTo"])
                    {
                        if (element["batch"]["sharedTo"].length > 0)
                        {
                            newShareObj = element;
                            hasBeenShared = true;
                        }
                    }
                    newAllBatchData.push(element["batch"]);
                    if (!props.materialSearch)
                    {
                    newBatches.push(
                        <BatchRow batchesErrorModes={props.batchesErrorModes} handleSetErrorModeDialog={handleSetErrorModeDialog} sharedToMe={false} handleBatchExecuteOpenClick={handleBatchExecuteOpenClick} setSelectedBatch={setSelectedBatch} handleCancelClick={handleCancelClick} reRender={reRender} setReRender={setReRender} setClickedOrder={setClickedOrder} shareObj={newShareObj} handleBatchShareOpenClick={handleBatchShareOpenClick} hasBeenShared={hasBeenShared} handleSetAnchorElBatch={handleSetAnchorElBatch} setAnchorElDownload={setAnchorElDownload} key={element["key"]} currentSelection={element["currentSelection"]} classes={element["classes"]} globalClasses={element["globalClasses"]} lastRun={element["lastRun"]} index={element["index"]} batch={element["batch"]} material={element["material"]} statusIcon={element["statusIcon"]} orderSecondaryText={element["orderSecondaryText"]} handleBatchClick={element["handleBatchClick"]} handleEditClick={element["handleEditClick"]}></BatchRow>
                    );
                    }
                    else
                    {
                        let matAndRev = (element["batch"]["material"] + "/" + element["batch"]["materialRevision"]);
                        if (element["batch"]["material"].toString().toLowerCase().indexOf(props.materialSearch.toLowerCase()) !== -1 
                        || element["batch"]["materialRevision"].toLowerCase().indexOf(props.materialSearch.toLowerCase()) !== -1 
                        || matAndRev.toLowerCase().indexOf(props.materialSearch.toLowerCase()) !== -1
                        || (element["batch"]["material"] + "/").toLowerCase().indexOf(props.materialSearch.toLowerCase()) !== -1
                        || element["batch"]["name"].toString().toLowerCase().indexOf(props.materialSearch.toLowerCase()) !== -1)
                        {
                            newBatches.push(
                            <BatchRow batchesErrorModes={props.batchesErrorModes} handleSetErrorModeDialog={handleSetErrorModeDialog} sharedToMe={false} handleBatchExecuteOpenClick={handleBatchExecuteOpenClick} setSelectedBatch={setSelectedBatch} handleCancelClick={handleCancelClick} reRender={reRender} setReRender={setReRender} setClickedOrder={setClickedOrder} shareObj={newShareObj} handleBatchShareOpenClick={handleBatchShareOpenClick} hasBeenShared={hasBeenShared} handleSetAnchorElBatch={handleSetAnchorElBatch} setAnchorElDownload={setAnchorElDownload} key={element["key"]} currentSelection={element["currentSelection"]} classes={element["classes"]} globalClasses={element["globalClasses"]} lastRun={element["lastRun"]} index={element["index"]} batch={element["batch"]} material={element["material"]} statusIcon={element["statusIcon"]} orderSecondaryText={element["orderSecondaryText"]} handleBatchClick={element["handleBatchClick"]} handleEditClick={element["handleEditClick"]}></BatchRow>
                            );
                        }
                    }
                });
            }
            
            setFilteredBatches(tmpFilteredBatches);
            currentBatches = newBatches;
            //console.log("newBatches: ", newBatches);
            if (newBatches.length == 0)
            {
                setShowEmpty(true);
            } 
            else 
            {
                setShowEmpty(false);
            }
            if (maxBatches > 0) {
                setNewBatchData(newAllBatchData.slice(0, maxBatches));
                setBatches(newBatches.slice(0, maxBatches));
            }
            else {
                setNewBatchData(newAllBatchData);
                setBatches(newBatches);
            }  
              
        }
        props.setReRender(!props.reRender);

        // let sharedBy = null;
        // const sharedByData = async () => {
        //   sharedBy = await getSharedBy(instance);
        // };

        // sharedByData().catch(console.error).then(() => {
        //   let sharedTemp = [];
        //   if(!sharedBy) {
        //     let sharedArr = sharedBy[0]["shared"];
        //     sharedArr.forEach(element => {
        //         sharedTemp.push(element["item"]);
        //     });
        //   }
        // if (props.showShared == true)
        // {
        //     let newData = null;
        //     let sharedBatches = [];
        //     const sharedData = async () => { newData = await getSharedWith(instance);}
        //     sharedData().catch(()=>{console.error}).then(() => {
        //         let tempObj = newData[0];
        //         if(tempObj) {
        //           Array.prototype.forEach.call(tempObj["sharedWith"], e => {
        //             sharedBatches.push(Object.keys(e["item"])[0]);
        //           });
        //         }
        //         newAllBatches = newAllBatches.filter((e) => sharedBatches.includes(e["batch"]["id"]));
        //         allBatches.sort((a, b) => new Date(a["lastRun"]).valueOf() - new Date(b["lastRun"]).valueOf()).reverse();
        //         //console.log("ALLBATCHES: ", allBatches);
        //         if (newAllBatches.length > 0) {
        //             setAllBatchesData(newAllBatches);
        //             setAllSharedData(sharedTemp);
        //             if (props.homePage == true) {
        //                 newAllBatches = newAllBatches.slice(0, 5);
        //             }
        //             newAllBatches.forEach(element => {
        //                 let batchEl = element["batch"];
        //                 let newShareObj = null;
        //                 let hasBeenShared = sharedTemp.some((el) => {
        //                     if (batchEl["name"] == Object.keys(el)[0] || batchEl["id"] == Object.keys(el)[0])
        //                     {
        //                         console.log("EL: ", el);
        //                         newShareObj = el;
        //                         return (batchEl["name"] == Object.keys(el)[0] || batchEl["id"] == Object.keys(el)[0]);
        //                     }
        //                 });
        //                 newBatches.push(
        //                     <BatchRow setClickedOrder={setClickedOrder} shareObj={newShareObj} handleBatchShareOpenClick={handleBatchShareOpenClick} hasBeenShared={hasBeenShared} handleSetAnchorElBatch={handleSetAnchorElBatch} setAnchorElDownload={setAnchorElDownload} key={element["key"]} currentSelection={element["currentSelection"]} classes={element["classes"]} globalClasses={element["globalClasses"]} lastRun={element["lastRun"]} index={element["index"]} batch={element["batch"]} material={element["material"]} statusIcon={element["statusIcon"]} orderSecondaryText={element["orderSecondaryText"]} handleBatchClick={element["handleBatchClick"]} handleEditClick={element["handleEditClick"]}></BatchRow>
        //                 );
        //             });
        //         }
        //         currentBatches = newBatches;
        //         if (maxBatches > 0) {
        //             setBatches(newBatches.slice(0, maxBatches));
        //         }
        //         else {
        //             setBatches(newBatches);
        //         }

        //     });           
        // }
        // else {
        //     newAllBatches = newAllBatches.filter((e) => e["batch"]["createdBy"] == accounts[0].username);
        //     allBatches.sort((a, b) => new Date(a["lastRun"]).valueOf() - new Date(b["lastRun"]).valueOf()).reverse();
        //     //console.log("ALLBATCHES: ", allBatches);
        //     if (newAllBatches.length > 0) {
                
        //         setAllBatchesData(newAllBatches);
        //         setAllSharedData(sharedTemp);
        //         if (props.homePage == true) {
        //             newAllBatches = newAllBatches.slice(0, 5);
        //         }
        //         newAllBatches.forEach(element => {
        //             let batchEl = element["batch"];
        //             let newShareObj = null;
        //             let hasBeenShared = sharedTemp.some((el) => {
        //                 if (batchEl["name"] == Object.keys(el)[0] || batchEl["id"] == Object.keys(el)[0])
        //                 {
        //                     newShareObj = el;
        //                     return (batchEl["name"] == Object.keys(el)[0] || batchEl["id"] == Object.keys(el)[0]);
        //                 }
        //             });
        //             newBatches.push(
        //                 <BatchRow setClickedOrder={setClickedOrder} shareObj={newShareObj} handleBatchShareOpenClick={handleBatchShareOpenClick} hasBeenShared={hasBeenShared} handleSetAnchorElBatch={handleSetAnchorElBatch} setAnchorElDownload={setAnchorElDownload} key={element["key"]} currentSelection={element["currentSelection"]} classes={element["classes"]} globalClasses={element["globalClasses"]} lastRun={element["lastRun"]} index={element["index"]} batch={element["batch"]} material={element["material"]} statusIcon={element["statusIcon"]} orderSecondaryText={element["orderSecondaryText"]} handleBatchClick={element["handleBatchClick"]} handleEditClick={element["handleEditClick"]}></BatchRow>
        //             );
        //         });
        //     }
        //     currentBatches = newBatches;
        //     if (maxBatches > 0) {
        //         setBatches(newBatches.slice(0, maxBatches));
        //     }
        //     else {
        //         setBatches(newBatches);
        //     }  
        // }

        // });
    }

    let emptyRows = 0;

    let name = props.excelPath;
    let excelData;
    let dataFetched = false;
    let batchesFetched = false;
    let result = [];

    const delay = ms => new Promise(res => setTimeout(res, ms));

    const updateIntervalBatches = async () => {
        let processingBatches = batchData.filter((b) => b["status"] == "Processing");
        let errorModeProcessingBatches = batchData.filter((b) => b["errorModeStatus"] != null && b["errorModeStatus"].toLowerCase() == "processing");
        //let tmpBatchData = batchData.filter((b) => processingBatches.includes(b));
        let tmpBatchData = batchData; 
        console.log("batchData: ", batchData);
        // console.log("newBatchData: ", newBatchData);
        // console.log("tmpBatchData: ", tmpBatchData);
        // console.log("processingBatches: ", processingBatches);
        // console.log("props.batches: ", props.batches);
        let refreshedBatches = processingBatches.map((b) => {
            return RefreshStatus(instance, b["id"], accounts[0].username);  
        });
        refreshedBatches = refreshedBatches.filter(function( element ) {
            return element !== undefined;
        });
        await Promise.all(refreshedBatches).then(data => {
			console.log("DATA: ", data);
            data.forEach(element => {
                var batch = tmpBatchData.findIndex((obj) => {
                    return obj["id"] == element["id"];
                }); 
                if (tmpBatchData[batch] != element)
                {                      
					console.log("tmpBatchData[batch]: ", tmpBatchData[batch]);
                    let originalOrders = tmpBatchData[batch]["orders"]; 
                    tmpBatchData[batch] = element;
                }            
            });
        }).catch((err) => {
            openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Error: " + err}</p></div>,300000);
        });
        
        errorModeProcessingBatches.forEach(element => {
            const foundVal = processingBatches.find((val) => val.id == element.id);
            if (foundVal == undefined)
            {
                processingBatches.push(element);
            }
        });
        const batchUpdates = processingBatches.map((b) => {
            return getBatch(instance, b["id"]);
        });
        await Promise.all(batchUpdates).then(async data => {
            let callFetchErrorModes = false;
			console.log("DATA2: ", data);
            data.forEach(element => {
                var batch = tmpBatchData.findIndex((obj) => {
                    return obj["id"] == element["id"];
                });
                if (tmpBatchData[batch] != element)
                {                
					console.log("tmpBatchData[batch]2: ", tmpBatchData[batch]);
                    if (tmpBatchData[batch]["errorModeStatus"] != null && element["errorModeStatus"] != null)
                    {
                        if (tmpBatchData[batch]["errorModeStatus"].toLowerCase() == "processing" && element["errorModeStatus"].toLowerCase() != "processing")
                        {
                            callFetchErrorModes = true;
                        }
                    }
                    let originalOrders = tmpBatchData[batch]["orders"];
                    tmpBatchData[batch] = element;
                }
            });
        if (callFetchErrorModes == true)
        {
            props.CallSetErrorModes();
            await delay(2000);
        }
        var date = new Date();
        props.setBatchesUpdatedTime(date);
        console.log("tmpBatchData: ", tmpBatchData);
        setBatchData([...tmpBatchData]);
        props.setBatches([...tmpBatchData]);
        mapBatches();
        setReRender(!reRender);
        }).catch((err) => {
            openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Error: " + err}</p></div>,300000);
        });
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (batches.length > 0)
            {
                //console.log("newBatchData: ", newBatchData);
                updateIntervalBatches();
            }
          }, 60000);
        
          return () => {
            clearInterval(interval);
        };
    },[batches]);

    useEffect(() => {
        if (location["pathname"] == "/") {
            setMaxBatches(5);
            setShowFooter(false);
        }
        else if (location["pathname"] == "/batches") {
            setMaxBatches(-1);
            setRowsPerPage(-1);
            setShowFooter(false);
            setEmptyTable(false);
        }
        setReRender(!reRender);
    }, [location]);


    useEffect(() => {
        mapBatches();
        //console.log("props.showEmpty: ", props.showEmpty);
        //console.log("showEmpty: ", showEmpty);
    }, [currentSelection, reRender, batchData])

    useEffect(() => {
        if (props.batches == null) {return;}
        setBatchData(props.batches);
        mapBatches();
        //console.log("props.batches: ", props.batches);
        //console.log("props.showEmpty: ", props.showEmpty);
        //console.log("showEmpty: ", showEmpty);
    
    }, [props.batches])

    useEffect(() => {
        if (props.batches == null) {return;}
        setBatchData(props.batches);
        mapBatches();
        //console.log("props.batches: ", props.batches);
        //console.log("props.showEmpty: ", props.showEmpty);
        //console.log("showEmpty: ", showEmpty);
        // console.log("allBatchesData: ", allBatchesData);
        // if (allBatchesData.length == 0) { return;}
        // let newBatches = [];
        // let tempAllBatches = allBatchesData;
        // if (props.homePage == true) {
        //     tempAllBatches = tempAllBatches.slice(0, 5);
        // }
        // else {
        //     if (props.statusFilter != null)
        //     {
        //         if (props.statusFilter.length == 0) {
        //             tempAllBatches = [];
        //         }
        //         if (!props.statusFilter.includes("All")){
        //             tempAllBatches = tempAllBatches.filter((e) => {
        //                 return props.statusFilter.includes(e["batch"]["status"]);
        //             });
        //         }
        //     }
        //     if (props.materialSearch != null)
        //     {
        //         if (props.materialSearch != "")
        //         {
        //             tempAllBatches = tempAllBatches.filter((e) => {
        //                 return e["batch"]["material"].toLowerCase().includes(props.materialSearch.toLowerCase());
        //             });
        //         }
        //     }    
        // }
        // tempAllBatches.forEach(element => {
        //     let batchEl = element["batch"];
        //     let newShareObj = null;
        //     let hasBeenShared = allSharedData.some((el) => {
        //         //console.log("EL: ", el);
        //         //console.log("batchEl: ", batchEl);
        //         if (batchEl["name"] == Object.keys(el)[0] || batchEl["id"] == Object.keys(el)[0])
        //         {
        //             newShareObj = el;
        //             return (batchEl["name"] == Object.keys(el)[0] || batchEl["id"] == Object.keys(el)[0]);
        //         }
        //     });
        //     if (element["batch"]["sharedTo"])
        //     {
        //         if (element["batch"]["sharedTo"].length > 0)
        //         {
        //             newShareObj = element;
        //             hasBeenShared = true;
        //         }
        //     }
        //     newBatches.push(
        //         <BatchRow setClickedOrder={setClickedOrder} shareObj={newShareObj} handleBatchShareOpenClick={handleBatchShareOpenClick} hasBeenShared={hasBeenShared} handleSetAnchorElBatch={handleSetAnchorElBatch} setAnchorElDownload={setAnchorElDownload} key={element["key"]} currentSelection={element["currentSelection"]} classes={element["classes"]} globalClasses={element["globalClasses"]} lastRun={element["lastRun"]} index={element["index"]} batch={element["batch"]} material={element["material"]} statusIcon={element["statusIcon"]} orderSecondaryText={element["orderSecondaryText"]} handleBatchClick={element["handleBatchClick"]} handleEditClick={element["handleEditClick"]}></BatchRow>
        //     );
        // });
        // currentBatches = newBatches;
        // if (maxBatches > 0) {
        //     setBatches([...newBatches.slice(0, maxBatches)]);
        // }
        // else {
        //     setBatches([...newBatches]);
        // }  

    },[allSharedData, location, props.homePage, props.statusFilter, props.materialSearch, props.batchesErrorModes]);

    useEffect(() => {
        setBatchesLoaded(25);
        setEndReached(false);
        setBatches(batches.slice(0, 26));
        mapBatches();
    }, [props.resetBatchesLoaded])

    return (
        // Filters
        emptyTable
        ?
        <></>
        :
        <>
        {
            batches.length == 0
            ?
            <CircularProgress />
            :
            <TableContainer component={Paper} className={clsx(!props.homePage && classes.tableContainer, props.homePage && classes.overflowHidden)}>
            <InfiniteScroll
            pageStart={0}
            loadMore={props.homePage == true ? ()=>{} : loading}
            hasMore={props.homePage == true ? false : (endReached && !batchesLoading) ? false : (true || false)}
            loader={props.materialSearch != "" ? <></> : <CircularProgress key={0} />}
            useWindow={false}
            >
            <Table className={classes.tableres}>
                <TableHead>
                    <TableRow key={-1} className={classes.batchTitleRow}>
                        {/* <TableCell>
                            <Checkbox 
                                indeterminate={numSelected > 0 && numSelected < rowCount}
                                checked={rowCount > 0 && numSelected === rowCount}
                                onChange={onSelectAllClick}
                            />
                        </TableCell> */}
                        <TableCell key={"Execute"} className={classes.batchTitleCell} style={{ width: "4%"}}>
                            {""}
                        </TableCell>
                        <TableCell key={"Batch"} className={classes.batchTitleCell} style={{ width: "45%" }}>
                            {""}
                        </TableCell>
                        <TableCell key={"Status"} className={classes.batchTitleCell} style={{ width: "5%" }}>
                            {""}
                        </TableCell>
                        <TableCell key={"Material"} className={classes.batchTitleCell}>
                            {""}
                        </TableCell>
                        <TableCell key={"ErrorMode"} className={classes.batchTitleCell} style={{ width: "17%" }}>
                            {"Error mode"}
                        </TableCell>
                        <TableCell key={"SkipMode"} className={classes.batchTitleCell} style={{ width: "16%" }}>
                            {"Skip mode"}
                        </TableCell>
                        <TableCell key={"SuccessRate"} className={classes.batchTitleCell} style={{ width: "25%" }} align="center">
                            {"Success rate"}
                        </TableCell>
                        <TableCell key={"LastRun"} align="center" className={classes.batchTitleCell} style={{ width: "15%" }}>
                            {"Last run"}
                        </TableCell>
                        <TableCell key={"OrderQty"} align="center" className={classes.batchTitleCell} style={{ width: "22%" }}>
                            {"No. of test orders"}
                        </TableCell>
                        <TableCell key={"Edit"} className={classes.batchTitleCell} style={{ width: "120px", padding: "0 60px" }}>
                            {""}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {batches}
                    {/* {batchesExcel} */}
                </TableBody>
                {
                    showFooter ?
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                    colSpan={3}
                                    count={batchesLen}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}

                                />
                            </TableRow>
                        </TableFooter>
                        :
                        null
                }
            </Table>
            </InfiniteScroll>

            <Menu
                id="simple-menu"
                anchorEl={anchorElBatch}
                getContentAnchorEl={null}
                keepMounted={false}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={Boolean(anchorElBatch)}
                onClose={handleBatchClose}
            // PaperProps={{
            //     style: {
            //       transform: 'translateX(0) translateY(64px)',
            //     },
            //   }}
            >
                <MenuItem disabled={editLocked} onClick={handleBatchEditClick}>Edit</MenuItem>
                <MenuItem disabled={props.showShared == true ? true : false} onClick={(e) => {handleBatchShareOpenClick(e, null)}}>Share</MenuItem>
                <MenuItem onClick={handleBatchCopyClick}>Copy</MenuItem>
                <MenuItem disabled={currentBatch == null ? true : currentBatch["createdBy"] != accounts[0].username ? true : currentBatch["status"] == "Processing"} onClick={handleBatchDeleteClick}>Delete</MenuItem>
            </Menu>

            <Menu
                id="simple-menu"
                anchorEl={anchorElDownload}
                getContentAnchorEl={null}
                keepMounted={false}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={Boolean(anchorElDownload)}
                
                onClose={handleDownloadClose}
            // PaperProps={{
            //     style: {
            //       transform: 'translateX(0) translateY(64px)',
            //     },
            //   }}
            >     
                {clickedOrder != null && clickedOrder["status"] == "JOB COMPLETED" && clickedOrder["configurationId"] != "" && (<MenuItem onClick={handleOrderXML}>Order XML</MenuItem>)}
                {clickedOrder != null && clickedOrder["caD_duration"] != null && clickedOrder["caD_duration"] != "not available" && clickedOrder["configurationId"] != "" && (<MenuItem onClick={handleCADLog}>CAD Log</MenuItem>)}
                {clickedOrder != null && clickedOrder["status"] == "JOB COMPLETED" && clickedOrder["caD_duration"] != "not available" && clickedOrder["configurationId"] != "" && (<MenuItem onClick={handleCADResult}>CAD Result</MenuItem>)}
                {clickedOrder != null && clickedOrder["status"] == "JOB COMPLETED" && clickedOrder["mC_color"] != null && clickedOrder["configurationId"] != "" && selectedBatch["executeArguments"]["levelOfScan"] == "deep_check" && selectedBatch["executeArguments"]["sendToCAD"] == "true" && (<MenuItem onClick={handleCADCheck}>CAD Check</MenuItem>)}
                {clickedOrder != null && clickedOrder["status"] == "JOB COMPLETED" && selectedBatch != null && selectedBatch["executeArguments"]["levelOfScan"] == "deep_check" && clickedOrder["configurationId"] != "" && (<MenuItem onClick={handleTrace}>Trace</MenuItem>)}
                {clickedOrder != null && clickedOrder["status"] == "JOB COMPLETED" && clickedOrder["configurationId"] != "" && selectedBatch["executeArguments"]["downloadMFG"] && (<MenuItem onClick={handleOrderMFGDownload}>MFG Package</MenuItem>)}
            </Menu>

            <ExecuteDialog 
                open={openExecuteDialog}
                setOpen={setOpenExecuteDialog}
                toggleAll={toggleAll}
                setToggleAll={setToggleAll}
                orderTitles={orderTitles}
                setOrderTitles={setOrderTitles}
                copyBatch={props.copyBatch}
                caseSelect={caseSelect}
                setCaseSelect={setCaseSelect}
                selectedBatchOrders={selectedBatchOrders}
                selectedBatch={GetEmpty(selectedBatch) ? "" : selectedBatch}
                setSelectedBatch={setSelectedBatch}
                selectedKoneMaterial={GetEmptyValue(selectedBatch, "material") ? "" : selectedBatch["material"]}
                selectedKoneMaterialRev={GetEmptyValue(selectedBatch, "materialRevision") ? "" : selectedBatch["materialRevision"]}
                selectedBatchID={GetEmptyValue(selectedBatch, "name") ? "" : selectedBatch["name"]}
                batchDescription={GetEmptyValue(selectedBatch, "description") ? "" : selectedBatch["description"]}
                newBatch={props.newBatch}
                batches={props.batches}
                setBatches={props.setBatches}
                setNewBatch={props.setNewBatch}
                setExecuteSettings={setExecuteSettings}
                mode={"batchtable"}
                reRender={reRender}
                setReRender={setReRender}
                setSkipModeValues={setSkipModeValues}
            />

            <Dialog open={shareDialogOpen} onClose={handleShareDialogClose} fullWidth={true} maxWidth="sm" classes={{paper: classes.dialogPaper}}>
                <DialogContent style={{ padding: "12px 0px" }}>
                    <div style={{display: "flex", flex: 1, padding: "0px 18px"}}>
                        <Typography variant="h5" color="textPrimary" align="left">
                            {'Share batch'}
                        </Typography>
                    <Tooltip title={"Share batch"} TransitionComponent={({ children }) => children}>
                    <span style={{marginLeft: "auto"}}>
                    <IconButton style={{marginLeft: "auto"}} disabled={!shareChanged} className={clsx(globalClasses.blueButton, shareChanged && globalClasses.blueButtonGlow, !shareChanged && globalClasses.disabledButton)} onClick={() => {
                            handleBatchShareClick();
                        }}>
                        <GroupAdd />
                    </IconButton>
                    </span>
                </Tooltip>
                    </div>
                    <Typography variant="body2" color="textPrimary" align="left" style={{padding: "0px 18px"}}>
                            {currentBatch == null ? " " : currentBatch["description"] != null ? currentBatch["name"] + ", " + currentBatch["description"] : currentBatch["name"] + ", "}
                        </Typography>
                    <div style={{padding: "0px 18px"}}>

                        <Autocomplete
                            freeSolo
                            multiple
                            autoSelect
                            options={shareObj == null ? tempUsers : tempUsers.filter((e) => {
                                return !(shareObj[Object.keys(shareObj)[0]].includes(e));
                            })}
                            size="small"
                            inputValue={shareInputValue}
                            onInputChange={(event, newInputValue) => {
                                setShareInputValue(newInputValue)
                            }}
                            //classes={{  input: classes.inputStyle }}
                            limitTags={1}
                            getOptionLabel={(option) => option}
                            ListboxProps={{ style: { maxHeight: '70vh' } }}
                            //style={{width: "100%"}}
                            style={{ width: "250px" }}
                            onChange={(e, obj) => {
                                handleSetSelectedShareUsers(e, obj);
                            }}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <ListItemText primary={option} />
                                </React.Fragment>

                            )
                            }
                            renderInput={(params) => (
                                <TextField id="outlined-basic" variant="outlined" {...params} placeholder={"To: Email"} />
                            )}
                        />
                    </div>
                    {/* If shared, show list */}
                    {shareObj == null ? null :
                    shareObj["batch"] == undefined ? null : 
                    <>
                    <Divider style={{marginTop: "16px", marginBottom: "8px", width: "100%"}} />
                    <Typography variant="h6" style={{padding: "0px 18px"}}>
                        Shared with
                    </Typography>
                    <div style={{padding: "0px 18px"}} className={classes.root}>
                        <List>
                            {
                                shareObj["batch"]["sharedTo"].map((e) => {
                                    return(
                                    <ListItem key={e} classes={{ root: classes.item }}>
                                        <ListItemText
                                            primary={e}
                                            />
                                            <Tooltip title={"Remove sharing"}>
                                            <IconButton style={{marginRight: "8px"}} className={classes.customButton} onClick={(event)=>{
                                                handleBatchRemoveShareClick(e, Object.keys(shareObj)[0]);
                                            }}>
                                                <CancelOutlined />
                                            </IconButton>
                                            </Tooltip>
                                    </ListItem>
                                    )
                                })
                            }
                            
                        </List>
                    </div>
                    </>
                    }
                  

                </DialogContent>
            </Dialog>

            <Dialog open={errorModeDialogOpen} fullWidth={true} maxWidth="sm">
                <DialogContent style={{ padding: "12px 0px" }}>
                    <div style={{display: "flex", flex: 1, padding: "0px 18px"}}>
                        <Typography variant="h5" color="textPrimary" align="left">
                            {'Error mode'}
                        </Typography>
                    <Tooltip title={"Close"} TransitionComponent={({ children }) => children}>
                    <span style={{marginLeft: "auto"}}>
                    <IconButton style={{marginLeft: "auto"}} className={clsx(globalClasses.blueButton)} onClick={() => {
                        handleCloseErrorMode();
                    }}>
                        <Cancel />
                    </IconButton>
                    </span>
                </Tooltip>
                    </div>
                    <List style={{padding: "0", margin: "8px"}}>
                        {
                           errorModeComponents
                        }
                    </List>
                  

                </DialogContent>
            </Dialog>
        </TableContainer>
        } 
        </>
    );
}