export const msalConfig = {
    auth: {
      clientId: "46a75edc-0232-4ad2-a03b-1e94a343c706",
      authority: "https://login.microsoftonline.com/2bb82c64-2eb1-43f7-8862-fdc1d2333b50", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: "https://cws.kone.com"
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["api://5f8c38a4-65ce-4da1-a0c3-bd4cc1a23484/Task.Consume"] // "User.Read", 
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  // export const graphConfig = {
  //     graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
  // };